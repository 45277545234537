import React from "react";
import "../css/story.css";
import { Link } from "react-router-dom";
import Footer from "./Footer";

function Story() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="story_box">
      <div className="header-container" style={{ backgroundColor: "#151515" }}>
        <div className="header">
          <div className="header__inner">
            <div className="width_bottom_inner">
              <div className="width_bot_log1">
                <img
                  width="192"
                  height="40"
                  alt=""
                  src="https://lemonade-it.com/wp-content/uploads/2024/06/Lemonade-Logo-White-e1719430604549.png"
                />
              </div>
              <div className="width_bot_link">
                <ul>
                  <li>
                    <Link to="/" onClick={toTop}>
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/shop" onClick={toTop}>
                      Shop
                    </Link>
                  </li>
                  <li>
                    <Link to="/about" onClick={toTop}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/cart/1" onClick={toTop}>
                      Cart
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" onClick={toTop}>
                      Schedule a Discussion
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about1_box" style={{ marginTop: "80px" }}>
        <div className="about1_box_inner">
          <div className="about1_box_inner_left">
            <h1>
              Crafting digital experiences
              <br />
              with a human touch.
            </h1>
            <p>
              We collaborate closely with our clients on every digital
              experience project. We believe that our clients bring their
              expertise in their brand, which we can combine with our expertise
              in digital experience.
            </p>
          </div>
          <div className="about1_box_inner_right">
            <img
              alt=""
              src="https://lemonade-it.com/wp-content/uploads/2024/08/About-Us-Hero-Img-Lemonade.png"
            />
          </div>
        </div>
      </div>

      <div className="about1_box">
        <div className="about1_box_inner">
          <div className="about2_left">
            <p>Beyond Clicks</p>
          </div>
          <div className="about2_right">
            <h2>
              Our tagline reflects{" "}
              <span style={{ color: "#ffffff" }}>
                our commitment to deeply understanding the customer journey
              </span>
              . We focus on the pre-website discovery process, analyze user
              behavior during site navigation, and study post-website
              experiences.
            </h2>
          </div>
        </div>

        <div className="about2_bot">
          <div className="about2_bot_inner">
            <img
              alt=""
              src="https://lemonade-it.com/wp-content/uploads/2024/08/About-Us-Beyond-Clicks-1-Lemonade.png"
            />
          </div>
          <div className="about2_bot_inner">
            <img
              alt=""
              src="	https://lemonade-it.com/wp-content/uploads/2024/08/About-Us-Beyond-Clicks-2-Lemonade-1.png"
            />
          </div>
          <div className="about2_bot_inner">
            <img
              alt=""
              src="https://lemonade-it.com/wp-content/uploads/2024/08/About-Us-Beyond-Clicks-3-Lemonade.png"
            />
          </div>
          <div className="about2_bot_inner">
            <img
              alt=""
              src="https://lemonade-it.com/wp-content/uploads/2024/09/About-Us-Beyond-Clicks-4-Lemonade.png"
            />
          </div>
        </div>
      </div>

      <div className="about3_box">
        <div className="about3_box_inner">
          <div className="about3_box_inner_left" style={{ marginRight: "3%" }}>
            <h2>Our mission.</h2>
            <p>
              Our mission is to thoroughly explore every facet of our clients’
              digital assets to craft a positive digital experience for their
              customers. This encompasses going beyond mere clicks on their
              marketing platform.
            </p>
          </div>
          <div className="about3_box_inner_left">
            <h2>Our vision.</h2>
            <p>
              We aspire to be valued partners, seamlessly integrated into our
              clients’ teams, as we work together to elevate and strengthen
              their brands across digital platforms.
            </p>
          </div>
        </div>
      </div>

      <div className="about4_box">
        <h2>Our values.</h2>
        <div className="about4_box_inner">
          <div className="about4_box_inner_flex">
            <div className="about4_body">
              <h4>Integrity</h4>
              <p>
                We do our business with complete honesty to serve our team and
                our clients with transparency and only promise what we can do.
              </p>
            </div>
            <div className="about4_body">
              <h4>Respect</h4>
              <p>
                We respect our team and our clients. We encourage and are open
                to every opinion and ideas from our team and clients.
              </p>
            </div>
            <div className="about4_body">
              <h4>Resourcefulness</h4>
              <p>
                We work to the highest standards, we are resourceful to resolve
                problems from our team and our clients.
              </p>
            </div>
            <div className="about4_body">
              <h4>Teamwork</h4>
              <p>
                We treat our clients as part of our own team, we work together
                and ensure each project is communicated effectively.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="about5_box">
        <h2>Clients who have trusted us with their brands.</h2>
        <div className="about5_box_inner">
          <div className="about5_box_body"></div>
        </div>
      </div> */}

      <div className="main5_div">
        <div className="main5_div_top">
          <h2>
            Lemonade is a{" "}
            <span style={{ color: "#0e46f0" }}>trusted partner</span>
            <br />
            for Web Design Services in Singapore.
          </h2>
        </div>
        <div className="main5_div_bot">
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Award-Winning-Website-Design-Services-Based-in-Singapore.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Award-Winning website design services based in Singapore</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/12-Years-of-Experience-in-Website-Design-Services.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>12 Years of experience in website design services</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Over-2000-Websites-Created.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Over 2,000 websites created</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Rated-4.9-Stars-in-Google-Reviews.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Rated 4.9 stars in Google Reviews</p>
            </div>
          </div>
        </div>
        <div className="main5_div_bot">
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Average-customer-retention-of-7-years.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Average customer retention of 7 years</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Local-Team-of-Singaporean-Web-Developer.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Local team of Singaporean web designer & developer</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/99_-Customer-Satisfaction.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>
                99% Customer satisfaction (Based on survey results as of
                December 2023)
              </p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/2-Hour-Response-Time.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Fast Response time</p>
            </div>
          </div>
        </div>
      </div>

      <div className="main7_div">
        <div className="main4_div_inner">
          <div className="main4_body">
            <h2>Revamp your digital experience with modern strategies.</h2>
            <p>
              After users click through to your website from our search engine
              marketing ads, what follows next? This inquiry guides the
              trajectory of every web design project journey we embark upon.
            </p>
            <p>
              For decades, we’ve collaborated with meaningful businesses to
              modernise their digital presence through innovative strategies.
              Our approach extends beyond mere clicks to enhance the entire
              digital journey of their clients, ensuring a seamless experience
              and leaving a lasting impression.
            </p>
            <Link to="/contact" onClick={toTop} className="main1_link">
              Schedule a Discussion
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Story;
