import React from "react";
import "../css/footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <div className="bottom-container">
        <div className="footer_inner">
          <div className="footer_inner_left">
            <div className="footer_inner_left_top">
              {" "}
              <Link to="/">
                <span className="et_pb_image_wrap ">
                  <img
                    decoding="async"
                    width="151"
                    height="36"
                    src="https://lemonade-it.com/wp-content/uploads/2024/06/Lemonade-Logo-Blue-e1719431188932.png"
                    alt="Lemonade Logo (blue)"
                  />
                </span>
              </Link>
            </div>
            <h2>
              Taking digital experience{" "}
              <span style={{ color: "#0e46f0" }}>Beyond Clicks</span>
            </h2>
            <p>
              Copyright 2024 © Lemonade
              <br />
              <Link to="/terms">Terms &amp; Conditions</Link> |{" "}
              <Link to="/privacy">Privacy Policy</Link> |{" "}
            </p>
          </div>
          <div className="footer_inner_mid"></div>
          <div className="footer_inner_right">
            <div className="right_top_img">
              <img
                width="100%"
                alt=""
                src="/head/foot.png"
                style={{ marginLeft: "-1.6rem" }}
              />
            </div>
            <div class="et_pb_text_inner">Account</div>
            <p className="foot_link_p">
              <Link to="/shop" onClick={toTop}>
                Shop
              </Link>
              <br />
              <Link to="/cart/1" onClick={toTop}>
                Cart
              </Link>
              <br />
              <Link to="/checkout" onClick={toTop}>
                Checkout
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
