const productData = [
  {
    id: 1,
    name: "A step by step guide to creating a professional WordPress website.",
    cartName:
      "E-book: Create a 5 pages Website that is worth $3,500 in 20 Days",
    des: "At Lemonade, we empower all, pros or newbies, to craft top-quality websites. Our vision is uniting developers, small business owners, designers, and marketers to create sites with the highest quality and best practices.",
    prePrice: "39.99",
    price: "19.99",
    chapter: [
      {
        id: 1,
        title: "Exploration.",
        des: "This chapter will guide you through the process of creating a comprehensive plan for your website. To facilitate understanding, we’ve included templates that you can use as practical guides.",
        topics: [
          "Establishing your objectives",
          "Understanding your customers",
          "Deciding on your design assets",
          "Conducting keyword research",
          "Analysing competitors",
        ],
      },
      {
        id: 2,
        title: "Prototyping.",
        des: "Learn how to create prototypes for your website.",
        topics: [
          "Sitemap planning",
          "Wireframe development",
          "Content crafting",
          "User testing",
        ],
      },
      {
        id: 3,
        title: "Website designing.",
        des: "Using your prototype, create your website design.",
        topics: [
          "Create your web design graphics with Canva",
          "Create your web design using XD",
        ],
      },
      {
        id: 4,
        title: "Website development.",
        des: "Learn how to develop your website using WordPress and the Divi Builder.",
        topics: [
          "Preparing your web design for development",
          "Setting up your web development environment",
          "Creating your web pages using Divi",
          "Optimising your website for mobile view",
        ],
      },
      {
        id: 5,
        title: "Inspection and website launch.",
        des: "Learn how to conduct checks on your website before launching your website.",
        topics: ["Inspection checklist", "Launch your website"],
      },
      {
        id: 6,
        title: "Post launch setup.",
        des: "Learn what you will need to do after you launch your website.",
        topics: [
          "WordPress security setup",
          "Doing backups",
          "Integration with Google tools",
          "Basic speed optimisation",
        ],
      },
    ],
    image:
      "https://lemonade-it.com/wp-content/uploads/2023/07/Create-a-5-page-website-thats-worth-3500-in-less-than-a-month-247x296.png",
  },
];

export default productData;
