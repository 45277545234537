import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import "../css/checkout.css";
import Footer from "./Footer";
import { useNavigate, Link } from "react-router-dom";

const CheckOut = () => {
  const { cartItems, getProductById } = useContext(CartContext);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const navigate = useNavigate();

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePlaceOrder = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const errors = {};

    if (!formData.firstName)
      errors.firstName = "Billing First name  is a required field.";
    if (!formData.email)
      errors.email = "Billing Last name  is a required field.";
    if (!formData.lastName)
      errors.lastName = "Billing Email  is a required field.";

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    alert("Order placed!");

    setFormErrors({});
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
    });
    navigate("/paysuccess");
  };

  const calculateTotal = () => {
    return cartItems
      .reduce((total, item) => {
        const product = getProductById(item.id);
        if (product) {
          const price = parseFloat(product.price.replace("$", ""));
          return total + price * item.quantity;
        }
        return total;
      }, 0)
      .toFixed(2);
  };

  return (
    <>
      <div className="main-container">
        <div
          className="header-container"
          style={{ backgroundColor: "#151515" }}
        >
          <div className="header">
            <div className="header__inner">
              <div className="width_bottom_inner">
                <div className="width_bot_log1">
                  <img
                    width="192"
                    height="40"
                    alt=""
                    src="https://lemonade-it.com/wp-content/uploads/2024/06/Lemonade-Logo-White-e1719430604549.png"
                  />
                </div>
                <div className="width_bot_link">
                  <ul>
                    <li>
                      <Link to="/" onClick={toTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop" onClick={toTop}>
                        Shop
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={toTop}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/cart/1" onClick={toTop}>
                        Cart
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={toTop}>
                        Schedule a Discussion
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="checkout_box">
          <div className="basket-title">
            <h1>Check out</h1>
            <div class="basket_div_blue"></div>
          </div>
          <div className="checkout_inner">
            <div className="checkout_body">
              <div className="checkout_body_left">
                <div className="checkout_body_left_inner">
                  <form>
                    <h3>Billing details</h3>
                    <div>
                      <p className="bill_p">
                        <label for="billing_first_name" class="">
                          First name&nbsp;
                          <abbr class="required" title="required">
                            *
                          </abbr>
                        </label>
                        <span class="bill-input-wrapper">
                          <input
                            type="text"
                            class="input-text "
                            name="firstName"
                            id="billing_first_name"
                            placeholder=""
                            value={formData.firstName}
                            onChange={handleChange}
                            autocomplete="given-name"
                          />
                          <br />
                          {formErrors.firstName && (
                            <span className="error-message">
                              {formErrors.firstName}
                            </span>
                          )}
                        </span>
                      </p>{" "}
                      <p className="bill_p">
                        <label for="lastName" class="">
                          Last name&nbsp;
                          <abbr class="required" title="required">
                            *
                          </abbr>
                        </label>
                        <span class="bill-input-wrapper">
                          <input
                            type="text"
                            class="input-text "
                            name="lastName"
                            id="lastName"
                            placeholder=""
                            value={formData.lastName}
                            onChange={handleChange}
                            autocomplete="family-name"
                          />
                          <br />
                          {formErrors.lastName && (
                            <span className="error-message">
                              {formErrors.lastName}
                            </span>
                          )}
                        </span>
                      </p>
                      <p className="bill_p" style={{ width: "93.5%" }}>
                        <label for="email" class="">
                          Email address&nbsp;
                          <abbr class="required" title="required">
                            *
                          </abbr>
                        </label>
                        <span class="bill-input-wrapper">
                          <input
                            type="email"
                            class="input-text "
                            name="email"
                            id="email"
                            placeholder=""
                            value={formData.email}
                            onChange={handleChange}
                            autocomplete="email username"
                          />
                          <br />
                          {formErrors.email && (
                            <span className="error-message">
                              {formErrors.email}
                            </span>
                          )}
                        </span>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
              <div className="checkout_body_right">
                <div className="checkout_body_right_top">
                  <form>
                    <h3>Your order</h3>
                    <div>
                      <table className="shop_table">
                        <thead>
                          <tr>
                            <th class="product-name">Product</th>
                            <th class="product-total">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          {cartItems.map((item) => {
                            const product = getProductById(item.id);
                            const subtotal = (
                              parseFloat(product.price.replace("$", "")) *
                              item.quantity
                            ).toFixed(2);
                            return (
                              <tr className="cart_item" key={item.id}>
                                <td className="product-name">
                                  {product.cartName}{" "}
                                  <strong className="product-quantity">
                                    × {item.quantity}
                                  </strong>
                                </td>
                                <td className="product-total">
                                  <span className="woocommerce-Price-amount amount">
                                    <bdi>
                                      <span className="woocommerce-Price-currencySymbol">
                                        $
                                      </span>
                                      {subtotal}
                                    </bdi>
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          <tr className="cart-subtotal">
                            <th>Subtotal</th>
                            <td>
                              <span className="woocommerce-Price-amount amount">
                                <bdi>
                                  <span className="woocommerce-Price-currencySymbol">
                                    $
                                  </span>
                                  {calculateTotal()}
                                </bdi>
                              </span>
                            </td>
                          </tr>
                          <tr className="order-total">
                            <th>Total</th>
                            <td>
                              <strong>
                                <span className="woocommerce-Price-amount amount">
                                  <bdi>
                                    <span className="woocommerce-Price-currencySymbol">
                                      $
                                    </span>
                                    {calculateTotal()}
                                  </bdi>
                                </span>
                              </strong>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>

                    <div className="checkout_pay">
                      <ul>
                        <li>
                          <label for="payment_method_hitpay">
                            HitPay{" "}
                            <div class="form-row-hitpay-payment-gateway-form">
                              <div class="payment-labels-container">
                                <div class="payment-labels hitpay-paynow">
                                  <label class="hitpay-paynow">
                                    <img
                                      src="https://lemonade-it.com/wp-content/plugins/hitpay-payment-gateway//assets/images/paynow.svg"
                                      alt="PayNow QR"
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>{" "}
                          </label>

                          <div className="checkout_pay_input">
                            <div className="checkout_paynow">
                              <p style={{ margin: "0" }}>Pay with PayNow</p>
                            </div>
                          </div>

                          <div className="form-row-place-order">
                            <p>
                              Your personal data will be used to process your
                              order, support your experience throughout this
                              website, and for other purposes described in our{" "}
                              <Link to="/privacy">privacy policy</Link>.
                            </p>
                            <button type="submit" onClick={handlePlaceOrder}>
                              Complete Payment
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};

export default CheckOut;
