import React from "react";
import { Link } from "react-router-dom";
import "../css/header.css";

function Header() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__inner">
          <div className="width_bottom_inner">
            <div className="width_bot_log1">
              <img
                width="192"
                height="40"
                alt=""
                src="https://lemonade-it.com/wp-content/uploads/2024/06/Lemonade-Logo-White-e1719430604549.png"
              />
            </div>
            <div className="width_bot_link">
              <ul>
                <li>
                  <Link to="/" onClick={toTop}>
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/shop" onClick={toTop}>
                    Shop
                  </Link>
                </li>
                <li>
                  <Link to="/about" onClick={toTop}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/cart/1" onClick={toTop}>
                    Cart
                  </Link>
                </li>
                <li>
                  <Link to="/contact" onClick={toTop}>
                    Schedule a Discussion
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
