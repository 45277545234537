import React, { useContext, useState } from "react";
import { CartContext } from "../Component/CartContext";
import productData from "../Datas/productData";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import "../css/cartpage.css";
import { useNavigate } from "react-router-dom";

const CartPage = () => {
  const { cartItems, removeFromCart, updateQuantity } = useContext(CartContext);
  const navigate = useNavigate();

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getProductById = (id) => {
    return productData.find((product) => product.id === id);
  };

  const toCheckOut = () => {
    if (cartItems.length === 0) {
      alert("Please add items to your cart before proceeding to checkout.");
      return;
    }
    navigate("/checkout");
  };

  const calculateTotal = () => {
    const total = cartItems.reduce((total, item) => {
      const product = getProductById(item.id);
      const price = parseFloat(product.price.replace("$", ""));
      return total + price * item.quantity || total + price * 1;
    }, 0);

    return total.toFixed(2);
  };

  const handleInputChange = (id, event) => {
    const newQuantity = parseInt(event.target.value, 10);
    if (!isNaN(newQuantity) && newQuantity >= 0) {
      updateQuantity(id, newQuantity);
    }
  };

  return (
    <>
      <div className="main-container">
        <div
          className="header-container"
          style={{ backgroundColor: "#151515" }}
        >
          <div className="header">
            <div className="header__inner">
              <div className="width_bottom_inner">
                <div className="width_bot_log1">
                  <img
                    width="192"
                    height="40"
                    alt=""
                    src="https://lemonade-it.com/wp-content/uploads/2024/06/Lemonade-Logo-White-e1719430604549.png"
                  />
                </div>
                <div className="width_bot_link">
                  <ul>
                    <li>
                      <Link to="/" onClick={toTop}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop" onClick={toTop}>
                        Shop
                      </Link>
                    </li>
                    <li>
                      <Link to="/about" onClick={toTop}>
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/cart/1" onClick={toTop}>
                        Cart
                      </Link>
                    </li>
                    <li>
                      <Link to="/contact" onClick={toTop}>
                        Schedule a Discussion
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cart-page">
          <div className="basket-title">
            <h1>View cart</h1>
            <div class="basket_div_blue"></div>
          </div>
          <div className="cart-total">
            <div className="cart-items">
              {cartItems.length === 0 ? (
                <h3 style={{ color: "black", textAlign: "center" }}>
                  Your Cart is Empty!
                </h3>
              ) : (
                <form>
                  <table>
                    <thead>
                      <tr>
                        <th class="product-remove"></th>
                        <th class="product-thumbnail"></th>
                        <th class="product-name">Product</th>
                        <th class="product-price">Price</th>
                        <th class="product-quantity">Quantity</th>
                        <th class="product-subtotal">Subtotal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cartItems.map((item, index) => {
                        const product = getProductById(item.id);
                        return (
                          <tr key={index}>
                            <td class="product-remove" width="5%">
                              <a
                                href="#"
                                class="remove"
                                onClick={() => removeFromCart(item.id)}
                              >
                                ×
                              </a>
                            </td>
                            <td width="5.5%">
                              <img
                                width="32"
                                height="39"
                                alt=""
                                src={product.image}
                              />
                            </td>
                            <td width="46%">
                              <Link to="/shop" className="shop_name_link">
                                {product.cartName}
                              </Link>
                            </td>
                            <td>
                              <span>${product.price}</span>
                            </td>
                            <td>
                              <input
                                type="number"
                                value={item.quantity || 1}
                                className="shop_input"
                                onChange={(e) => handleInputChange(item.id, e)}
                                min="0"
                              />
                            </td>
                            <td>
                              <span>${calculateTotal()}</span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </form>
              )}
            </div>
            {cartItems.length > 0 && (
              <>
                <div className="shop_total">
                  <h2>Cart totals</h2>
                  <table
                    cellspacing="0"
                    class="shop_table shop_table_responsive"
                  >
                    <tbody>
                      <tr class="cart-subtotal">
                        <th
                          style={{
                            borderBottom: "1px solid rgba(51, 51, 51, 0.1)",
                          }}
                        >
                          Subtotal
                        </th>
                        <td
                          data-title="Subtotal"
                          style={{
                            borderBottom: "1px solid rgba(51, 51, 51, 0.1)",
                          }}
                        >
                          <span class="woocommerce-Price-amount amount">
                            <bdi>
                              <span class="woocommerce-Price-currencySymbol">
                                $
                              </span>
                              {calculateTotal()}
                            </bdi>
                          </span>
                        </td>
                      </tr>
                      <tr class="order-total">
                        <th>Total</th>
                        <td data-title="Total">
                          <strong>
                            <span class="woocommerce-Price-amount amount">
                              <bdi>
                                <span class="woocommerce-Price-currencySymbol">
                                  $
                                </span>
                                {calculateTotal()}
                              </bdi>
                            </span>
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="shop_check_btn">
                  <button class="shop_checkout" onClick={toCheckOut}>
                    Proceed to checkout
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CartPage;
