import React, { useState, useContext } from "react";
import { CartContext } from "../Component/CartContext";
import Header from "./Header";
import Footer from "./Footer";
import "../css/shop.css";
import { Link } from "react-router-dom";
import productData from "../Datas/productData";

function Shop() {
  const { addToCart } = useContext(CartContext);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleAddToCart = (book) => {
    addToCart(book.id, 1);
    toTop();
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="shop1_div">
        <div className="shop1_box">
          <div className="shop1_box_left">
            {productData.map((book, index) => (
              <div key={index}>
                <h1>{book.name}</h1>
                <div className="shop1_des">{book.des}</div>
                <div className="shop1_price">
                  Buy now at <s>USD {book.prePrice}</s> USD {book.price}
                </div>
                <Link
                  to={`/cart/${book.id}`}
                  onClick={() => handleAddToCart(book)}
                >
                  Buy eBook
                </Link>
              </div>
            ))}
          </div>
          <div className="shop1_box_right">
            <img
              width="627"
              height="628"
              src="https://lemonade-it.com/wp-content/uploads/2024/08/eBook-Hero-Img-Lemonade.png"
              alt="Ebook Hero Img Lemonade"
            />
          </div>
        </div>
      </div>

      <div className="shop2_box">
        <div className="shop2_box_inner">
          <h2>What can you expect from the eBook?</h2>
          <p>
            Our team has crafted an exceptionally comprehensive eBook tailored
            for individuals aspiring to create top-tier websites. Leveraging
            Lemonade’s established web design and development process, we
            provide detailed guidance on crafting a WordPress website valued at
            $4,000.
          </p>
          <p style={{ marginBottom: "15px" }}>
            This eBook presents a 20-day program designed for step-by-step
            progression towards building a professional website. By dedicating a
            maximum of 6 hours per day, you can efficiently navigate through
            each stage of the process.
          </p>
          <div>
            {productData.map((list, index) => (
              <>
                <div key={index} className="shop2_grid_div">
                  {list.chapter.map((chap, index) => (
                    <div className="shop2_grid_body" key={index}>
                      <div className="shop2_body_inner">
                        <h4>Chapter {chap.id}</h4>
                        <h3>{chap.title}</h3>
                        <p style={{ marginTop: "40px" }}>{chap.des}</p>
                        <p>Topics include:</p>
                        <ol>
                          {chap.topics.map((topics, index) => (
                            <li key={index}>{topics}</li>
                          ))}
                        </ol>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="shop2_buy">
                  <Link
                    to={`/cart/${list.id}`}
                    onClick={() => handleAddToCart(list)}
                  >
                    Buy eBook
                  </Link>
                </div>

                <div class="et_pb_text_inner1">
                  Buy now at <s>USD {list.prePrice}</s> USD {list.price}
                </div>
              </>
            ))}
          </div>
        </div>
      </div>

      <div className="main5_div">
        <div className="main5_div_top">
          <h2>
            Lemonade is a
            <span style={{ color: "#0e46f0" }}>trusted partner</span>
            <br />
            for Web Design Services in Singapore.
          </h2>
        </div>
        <div className="main5_div_bot">
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Award-Winning-Website-Design-Services-Based-in-Singapore.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Award-Winning website design services based in Singapore</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/12-Years-of-Experience-in-Website-Design-Services.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>12 Years of experience in website design services</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Over-2000-Websites-Created.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Over 2,000 websites created</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Rated-4.9-Stars-in-Google-Reviews.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Rated 4.9 stars in Google Reviews</p>
            </div>
          </div>
        </div>
        <div className="main5_div_bot">
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Average-customer-retention-of-7-years.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Average customer retention of 7 years</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Local-Team-of-Singaporean-Web-Developer.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Local team of Singaporean web designer & developer</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/99_-Customer-Satisfaction.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>
                99% Customer satisfaction (Based on survey results as of
                December 2023)
              </p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/2-Hour-Response-Time.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Fast Response time</p>
            </div>
          </div>
        </div>
      </div>

      <div className="shop3_box">
        <div className="shop3_box_inner">
          <h2>Web development technology recommendations.</h2>
          <p>
            We choose to work with only the best so that we can ensure our
            quality commitment to you.
            <br /> The technology we have chosen to work on are rated highly by
            3rd Party Review Platform - Trustpilot.
          </p>
          <div className="shop3_body_flex">
            <div className="shop3_body_flex_inner">
              <h3>Siteground</h3>
              <p>
                SiteGround is a Lemonade’s Recommendation for Server. Siteground
                uses Premium Google Cloud infrastructure for top reliability,
                security and speed.
              </p>
            </div>
            <div className="shop3_body_flex_inner">
              <h3>WPMUDev</h3>
              <p>
                Lemonade uses WPMUDEV to support our clients with consistent
                updates and security.
              </p>
            </div>
            <div className="shop3_body_flex_inner">
              <h3>Divi by Elegant Theme</h3>
              <p>
                Lemonade recommends Divi Theme for all our clients. Divi theme
                has been in business since 2008 and has more than 2,295,640
                websites using Divi.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="shop4_box">
        <div className="shop4_box_inner">
          <div className="shop4_box_inner_left">
            <h2>Questions that everyone asked.</h2>
            <div className="shop4_contact_txt">
              <p>
                If you have any specific questions about
                <br /> our services, feel free to ask us.
              </p>
              <p>
                E:&nbsp;
                <a href="mailto:rice@pulpx.xyz">rice@pulpx.xyz</a>
                <br /> T:&nbsp;<a href="tel:(415) 425-9345">(415) 425-9345</a>
              </p>
            </div>
          </div>

          <div className="shop4_box_inner_right">
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                How much is your ebook?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>The ebook is available at USD 39.99.</p>
                </div>
              )}
            </div>
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                How long does it take to design and develop my own website?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>
                    The time to complete the website will vary depending on
                    factors such as complexity and individual pace. However, our
                    20-day program, with a commitment of up to 6 hours per day,
                    is designed to efficiently guide you through the process.
                  </p>
                </div>
              )}
            </div>
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                There are so many youtube tutorials, why should I buy your
                ebook?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>
                    Every developer has their own unique approach to creating
                    websites. Our eBook offers you the best practices in web
                    design and development. Drawing from over a decade of
                    experience, we’ve meticulously crafted a step-by-step guide
                    to ensure you never feel lost while creating your website.
                  </p>
                </div>
              )}
            </div>
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                Do I need any prior experience in web development to benefit
                from this eBook?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>
                    Having prior experience in WordPress Design and Development
                    is advantageous. However, since the book caters to both
                    professionals and newcomers, it’s designed to be easily
                    comprehensible even for beginners. Basic computing skills
                    are a prerequisite, of course.
                  </p>
                </div>
              )}
            </div>
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                Can I customise the website according to my specific needs and
                preferences?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>
                    Absolutely! Our guide empowers you to customise every aspect
                    of your website to suit your unique requirements and
                    preferences.
                  </p>
                </div>
              )}
            </div>
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                Does your eBook cover the latest trends and technologies in web
                design and development?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>
                    Yes, we regularly update our eBook to reflect the latest
                    trends and technologies in web design and development,
                    ensuring you stay ahead in the ever-evolving digital
                    landscape.
                  </p>
                </div>
              )}
            </div>
            <div className="shop4_box_right_inner">
              <h4 onClick={toggleVisibility} style={{ cursor: "pointer" }}>
                Can I use the techniques taught in the eBook for platforms other
                than WordPress?
                <span style={{ marginLeft: "10px" }} className="h4_span">
                  {isVisible ? "-" : "+"}
                </span>
              </h4>
              {isVisible && (
                <div className="shop4_body_none">
                  <p>
                    While our guide primarily focuses on WordPress and Divi
                    Builder, many of the principles and techniques can be
                    adapted for use with other platforms as well.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="main2_div">
        <div className="main2_div_top">
          <h2>
            Crafting digital experiences for humans is what we do.
            <br />
            Exploring Beyond Clicks is how we do.
          </h2>
          <div className="main2_div_flex">
            <div className="main2_body_inner main2_first">
              <div className="main2_body">
                <h4>Web Design and Development</h4>
                <p>
                  Partner with our digital experience team to craft your brand’s
                  first impression. Starting with thorough research and
                  strategic planning, our web design is top-tier, backed by a
                  proven track record of success.
                </p>
              </div>
            </div>
            <div className="main2_body_inner main2_two">
              <div className="main2_body">
                <h4>eCommerce Website Design and Development</h4>
                <p>
                  The Best eCommerce Website Incorporates a Smooth Purchasing
                  Journey and Efficient Cost Management. Our team has partnered
                  with only the best to provide you with just that.
                </p>
              </div>
            </div>

            <div className="main2_body_inner main2_third">
              <div className="main2_body">
                <h4>Wordpress Managed</h4>
                <p>
                  The LemonadeCares Team is dedicated to safeguarding your
                  website with top-level security measures while also saving you
                  time by providing assistance with site updates.
                </p>
              </div>
            </div>
          </div>

          <div className="main2_schedule">
            <Link to="/contact" onClick={toTop} className="main1_link">
              Schedule a Discussion
            </Link>
          </div>
        </div>
      </div>

      <div className="main6_div">
        <div className="main6_div_inner">
          <div className="main6_body_left">
            <h4>Dive into in-depth insights.</h4>
            <div className="main6_body_left_flex">
              <div className="main6_body_left_inner">
                <div className="main6_left_img">
                  <img
                    alt=""
                    src="https://lemonade-it.com/wp-content/uploads/2023/04/Myth-about-Wordpress-Wordpress-is-only-use-for-Blogging.png"
                  />
                </div>
                <h4 className="main6_left_h4">
                  Myth about WordPress – WordPress is only use for Blogging
                </h4>
                <p>
                  Is Wordpress suitable for your business? How easy is it to
                  create a Wordpress Website. Wordpress is not just for
                  blogging- Let's bust the myth.
                </p>
              </div>
              <div className="main6_body_left_inner">
                <div className="main6_left_img">
                  <img
                    alt=""
                    src="https://lemonade-it.com/wp-content/uploads/2022/08/Lemonade-IT-Singapore-10-features-you-need-in-your-ecommerce-website-feature-image.jpg"
                  />
                </div>
                <h4 className="main6_left_h4">
                  10 Features You Need in Your Ecommerce Website
                </h4>
                <p>
                  Brand Story Most ecommerce websites are seen as marketplaces
                  where goods and services are bought and sold, with pages being
                  populated with categories of imported products that follow
                  market trends. Contrary to popular belief, e-commerce is more
                  than just a digital store - more often than not, they play an…
                </p>
              </div>
            </div>
          </div>

          <div className="main6_body_right">
            <h4>Stay current with tech trends.</h4>
            <div className="main6_right_body">
              <div className="main6_body_inner">
                <img
                  width="145"
                  height="90"
                  alt=""
                  src="	https://lemonade-it.com/wp-content/uploads/2024/05/Common-Wordpress-Errors-and-How-to-Debug-it.png"
                />
                <h5>Common WordPress Errors and How to Debug it</h5>
              </div>
              <div className="main6_body_inner">
                <h5>Optimising your website for user experience</h5>
              </div>
              <div className="main6_body_inner">
                <h5>
                  Speculative Loading – A New Plugin Released by WordPress for
                  Instant Load Time
                </h5>
              </div>
              <div className="main6_body_inner">
                <h5>Mobile First Design Vs Responsive Web Design</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main7_div">
        <div className="main4_div_inner">
          <div className="main4_body">
            <h2>Revamp your digital experience with modern strategies.</h2>
            <p style={{ marginBottom: "5%" }}>
              For decades, we’ve collaborated with meaningful businesses to
              modernise their digital presence through innovative strategies.
              Our approach extends beyond mere clicks to enhance the entire
              digital journey of their clients, ensuring a seamless experience
              and leaving a lasting impression.
            </p>
            <Link to="/contact" onClick={toTop} className="main1_link">
              Schedule a Discussion
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Shop;
