import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./Component/Main";
import Privacy from "./Component/Privacy";
import Terms from "./Component/Terms";
import Story from "./Component/Story";
import Contact from "./Component/Contact";
import Shop from "./Component/Shop";
import { CartProvider } from "./Component/CartContext";
import CartPage from "./Component/CartPage";
import CheckOut from "./Component/CheckOut";
import Success from "./Component/SuccessPay";

function App() {
  return (
    <CartProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/paysuccess" element={<Success />} />
          <Route path="/checkout" element={<CheckOut />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/about" element={<Story />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart/:id" element={<CartPage />} />
        </Routes>
      </Router>
    </CartProvider>
  );
}

export default App;
