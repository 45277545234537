import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../css/main.css";
import { Link } from "react-router-dom";

function Main() {
  const toTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className="main-container">
      <Header />
      <div className="main1_div">
        <div className="main1_box">
          <div className="theme-background dark-theme"></div>
          <div className="main1_box_inner">
            <h1>
              Singapore’s trusted
              <br />
              Web Design Agency.
            </h1>
            <h2>A seamless extension of your marketing team.</h2>
            <p>
              12 Years Experience · Avg. 8 Years Client Loyalty · 5-Star Rated
            </p>
            <Link to="/contact" onClick={toTop} className="main1_link">
              Schedule a Discussion
            </Link>
          </div>
        </div>
      </div>

      <div className="main2_div">
        <div className="main2_div_top">
          <h2>
            Crafting digital experiences for humans is what we do.
            <br />
            Exploring Beyond Clicks is how we do.
          </h2>
          <div className="main2_div_flex">
            <div className="main2_body_inner main2_first">
              <div className="main2_body">
                <h4>Web Design and Development</h4>
                <p>
                  Partner with our digital experience team to craft your brand’s
                  first impression. Starting with thorough research and
                  strategic planning, our web design is top-tier, backed by a
                  proven track record of success.
                </p>
              </div>
            </div>
            <div className="main2_body_inner main2_two">
              <div className="main2_body">
                <h4>eCommerce Website Design and Development</h4>
                <p>
                  The Best eCommerce Website Incorporates a Smooth Purchasing
                  Journey and Efficient Cost Management. Our team has partnered
                  with only the best to provide you with just that.
                </p>
              </div>
            </div>

            <div className="main2_body_inner main2_third">
              <div className="main2_body">
                <h4>Wordpress Managed</h4>
                <p>
                  The LemonadeCares Team is dedicated to safeguarding your
                  website with top-level security measures while also saving you
                  time by providing assistance with site updates.
                </p>
              </div>
            </div>
          </div>

          <div className="main2_schedule">
            <Link to="/contact" onClick={toTop} className="main1_link">
              Schedule a Discussion
            </Link>
          </div>
        </div>
      </div>

      <div className="main3_div">
        <div className="main3_div_body">
          <div className="main3_body_left">
            <h2>Featured work</h2>
            <p>
              Join more than&nbsp;
              <span style={{ color: "#151515" }}>
                <strong>2,000</strong>
              </span>
              &nbsp;businesses that have launched their websites and elevated
              their success in Search Engine Marketing.
            </p>
          </div>

          <div className="main3_body_right">
            <div className="main3_body_right_body">
              <div className="main3_wordpress main3_first">
                <h4>Verbum Vitae</h4>
                <p>eCommerce shop for Christian books</p>
                <div className="main3_entry-summary">
                  Online Shop for a small business.
                </div>
              </div>
              <div className="main3_wordpress main3_second">
                <h4>Renal Works</h4>
                <p>Healthcare SAAS Website</p>
                <div className="main3_entry-summary">
                  A corporate website featuring modern design and strong
                  branding elements.
                </div>
              </div>
              <div className="main3_wordpress main3_third">
                <h4>Ngee Beng Trading</h4>
                <p>eCommerce shop for a provision shop</p>
                <div className="main3_entry-summary">
                  Online Shop for a small business.
                </div>
              </div>
              <div className="main3_wordpress main3_four">
                <h4>Haviland</h4>
                <p>eCommerce shop for a designer porcelain</p>
                <div className="main3_entry-summary">
                  Online Shop featuring minimalist design.
                </div>
              </div>
              <div className="main3_wordpress main3_five">
                <h4>EstheCosme</h4>
                <p>eCommerce shop for beauty products</p>
                <div className="main3_entry-summary">
                  Online Shop for a small business.
                </div>
              </div>
              <div className="main3_wordpress main3_six">
                <h4>Daum Singapore</h4>
                <p>eCommerce shop for a designer crystals</p>
                <div className="main3_entry-summary">
                  Online Shop featuring minimalist design.
                </div>
              </div>
              <div className="main3_wordpress main3_seven">
                <h4>Beit Ballout</h4>
                <p>eCommerce shop for olive oil</p>
                <div className="main3_entry-summary">
                  Online Shop for a small business.
                </div>
              </div>
              <div className="main3_wordpress main3_eight">
                <h4>Beauty Japan</h4>
                <p>eCommerce shop for beauty products</p>
                <div className="main3_entry-summary">
                  Online Shop for a small business.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main4_div">
        <div className="main4_div_inner">
          <div className="main4_body">
            <h2>Beyond Clicks</h2>
            <p>
              After users click through to your website from our search engine
              marketing ads, what follows next? This inquiry guides the
              trajectory of every web design project journey we embark upon.
            </p>
            <p>
              At Lemonade, we transcend mere clicks. We analyse user behaviour,
              merging it with our technical expertise to create an unforgettable
              digital experience for your users.
            </p>
          </div>
        </div>
      </div>

      <div className="main5_div">
        <div className="main5_div_top">
          <h2>
            Lemonade is a{" "}
            <span style={{ color: "#0e46f0" }}>trusted partner</span>
            <br />
            for Web Design Services in Singapore.
          </h2>
        </div>
        <div className="main5_div_bot">
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Award-Winning-Website-Design-Services-Based-in-Singapore.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Award-Winning website design services based in Singapore</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/12-Years-of-Experience-in-Website-Design-Services.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>12 Years of experience in website design services</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Over-2000-Websites-Created.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Over 2,000 websites created</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Rated-4.9-Stars-in-Google-Reviews.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Rated 4.9 stars in Google Reviews</p>
            </div>
          </div>
        </div>
        <div className="main5_div_bot">
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Average-customer-retention-of-7-years.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Average customer retention of 7 years</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/Local-Team-of-Singaporean-Web-Developer.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Local team of Singaporean web designer & developer</p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/99_-Customer-Satisfaction.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>
                99% Customer satisfaction (Based on survey results as of
                December 2023)
              </p>
            </div>
          </div>
          <div className="main5_flex">
            <div className="main5_flex_left">
              <img
                decoding="async"
                src="https://lemonade-it.com/wp-content/uploads/2024/08/2-Hour-Response-Time.svg"
                alt="Award Winning Website Design Services Based In Singapore"
              />
            </div>
            <div className="main5_flex_right">
              <p>Fast Response time</p>
            </div>
          </div>
        </div>
      </div>

      <div className="main6_div">
        <div className="main6_div_inner">
          <div className="main6_body_left">
            <h4>Dive into in-depth insights.</h4>
            <div className="main6_body_left_flex">
              <div className="main6_body_left_inner">
                <div className="main6_left_img">
                  <img
                    alt=""
                    src="https://lemonade-it.com/wp-content/uploads/2024/10/Drive-Sales-with-Expert-eCommerce-SEO-Techniques-Featured-Image-Lemonade.jpg"
                  />
                </div>
                <h4 className="main6_left_h4">
                  {" "}
                  Drive Sales with Expert eCommerce SEO Techniques{" "}
                </h4>
                <p>
                  Want to excel in eCommerce SEO? Learn proven tips to improve
                  your site's ranking and boost sales. Dive in now and transform
                  your online business!
                </p>
              </div>
              <div className="main6_body_left_inner">
                <div className="main6_left_img">
                  <img
                    alt=""
                    src="https://lemonade-it.com/wp-content/uploads/2024/10/Why-Are-Other-Measurements-Important-for-SEO-and-Not-Just-Ranking_.png"
                  />
                </div>
                <h4 className="main6_left_h4">
                  Why Are Other Measurements Important for SEO, and Not Just
                  Ranking?
                </h4>
                <p>
                  Learn why relying only on rankings can be a mistake for SEO.
                  Discover the importance of other measurements and optimize
                  your strategy effectively.
                </p>
              </div>
            </div>
          </div>

          <div className="main6_body_right">
            <h4>Stay current with tech trends.</h4>
            <div className="main6_right_body">
              <div className="main6_body_inner">
                <img
                  width="145"
                  height="90"
                  alt=""
                  src="	https://lemonade-it.com/wp-content/uploads/2024/05/Common-Wordpress-Errors-and-How-to-Debug-it.png"
                />
                <h5>Common WordPress Errors and How to Debug it</h5>
              </div>
              <div className="main6_body_inner">
                <h5>Optimising your website for user experience</h5>
              </div>
              <div className="main6_body_inner">
                <h5>
                  Speculative Loading – A New Plugin Released by WordPress for
                  Instant Load Time
                </h5>
              </div>
              <div className="main6_body_inner">
                <h5>Mobile First Design Vs Responsive Web Design</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="main7_div">
        <div className="main4_div_inner">
          <div className="main4_body">
            <h2>Revamp your digital experience with modern strategies.</h2>
            <p>
              After users click through to your website from our search engine
              marketing ads, what follows next? This inquiry guides the
              trajectory of every web design project journey we embark upon.
            </p>
            <p>
              For decades, we’ve collaborated with meaningful businesses to
              modernise their digital presence through innovative strategies.
              Our approach extends beyond mere clicks to enhance the entire
              digital journey of their clients, ensuring a seamless experience
              and leaving a lasting impression.
            </p>
            <Link to="/contact" onClick={toTop} className="main1_link">
              Schedule a Discussion
            </Link>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Main;
